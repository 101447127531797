import request from '@/utils/request'

// 查询设备数据更新列表
export function listUpdate(query) {
  return request({
    url: '/equipment/update/list',
    method: 'get',
    params: query
  })
}

// 查询设备数据更新详细
export function getUpdate(dataUpdateId) {
  return request({
    url: '/equipment/update/' + dataUpdateId,
    method: 'get'
  })
}

// 新增设备数据更新
export function addUpdate(data) {
  return request({
    url: '/equipment/update',
    method: 'post',
    data: data
  })
}

// 修改设备数据更新
export function updateUpdate(data) {
  return request({
    url: '/equipment/update',
    method: 'put',
    data: data
  })
}

// 删除设备数据更新
export function delUpdate(dataUpdateId) {
  return request({
    url: '/equipment/update/' + dataUpdateId,
    method: 'delete'
  })
}

// 导出设备数据更新
export function exportUpdate(query) {
  return request({
    url: '/equipment/update/export',
    method: 'get',
    params: query
  })
}
// 查询未关联的数据
export function getListDataNoIn(query) {
  return request({
    url: '/equipment/update/listDataNoIn',
    method: 'get',
    params: query
  })
}