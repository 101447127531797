import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
// 登录方法
export function agentLogin(agentId, agentSecret) {
  const data = {
    agentId,
    agentSecret
  }
  return request({
    url: '/agentLogin',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

//统一登录
export function ssoLogin(back,ticket) {
  return request({
    url: '/sso/login?back='+back+"",
    method: 'post'
  })
}

//获取ssoToken
export function getSsoTokenByTokenMd5(tokenMd5Str) {
  return request({
    url: '/getSsoTokenByTokenMd5?tokenMd5Str='+tokenMd5Str+"",
    method: 'get'
  })
}
