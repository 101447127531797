<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      v-show="showSearch"
      label-width="68px"
    >
      <!-- <el-form-item label="数据类型" prop="dataTypeId">
        <el-select
          v-model="queryParams.dataTypeId"
          placeholder="数据类型"
          clearable
          size="small"
        >
          <el-option key="1" label="视频" value="1" />
          <el-option key="2" label="图片" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="数据名称" prop="dataName">
        <el-input
          v-model="queryParams.dataName"
          placeholder="请输入数据名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="下载地址" prop="downloadUrl">
        <el-input
          v-model="queryParams.downloadUrl"
          placeholder="请输入下载地址"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item> -->
      <el-form-item label="状态" prop="status">
        <el-select
          v-model="queryParams.status"
          placeholder="状态"
          clearable
          size="small"
        >
          <el-option
            v-for="dict in statusOptions"
            :key="dict.dictValue"
            :label="dict.dictLabel"
            :value="dict.dictValue"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="cyan"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['equipment:update:add']"
          >新增</el-button
        >
      </el-col>
      <!-- <el-col :span="1.5">
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['biz:update:edit']"
          >修改</el-button
        >
      </el-col> -->
      <el-col :span="1.5">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['equipment:update:remove']"
          >删除</el-button
        >
      </el-col>

      <right-toolbar
        :showSearch.sync="showSearch"
        @queryTable="getList"
      ></right-toolbar>
    </el-row>

    <el-table
      v-loading="loading"
      :data="updateList"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="离线数据ID" align="center" prop="offlineDataId" />
      <el-table-column label="数据名称" align="center" prop="dataName" />
      <el-table-column
        label="下载地址"
        align="center"
        prop="downloadUrl"
        width="550"
      />
      <el-table-column label="状态" align="center" width="100">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            active-value="1"
            inactive-value="0"
            @change="handleStatusChange(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <!-- <el-table-column label="备注" align="center" prop="remark" /> -->
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <!-- <el-button
            icon="el-icon-plus"
            size="mini"
            type="text"
            @click="handleRelevance(scope.row)"
            v-hasPermi="['biz:update:add']"
            >关联</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['biz:update:edit']"
            >修改</el-button
          > -->
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['equipment:update:remove']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改设备数据更新对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="open"
      width="50%"
      append-to-body
    >
      <el-table
        ref="multipleTable"
        :data="noInData"
        tooltip-effect="dark"
        class="tourist-list"
        @row-click="noInDataSelect"
      >
        <el-table-column width="60" align="center">
          <template slot-scope="scope">
            <el-radio
              :label="scope.row.offlineDataId"
              v-model="selectOfflineDataId"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column label="离线数据ID" prop="offlineDataId" align="center">
        </el-table-column>
        <el-table-column prop="dataName" label="数据名称" align="center"> </el-table-column>
        <el-table-column prop="downloadUrl" label="下载地址" width="500" align="center">
        </el-table-column>
      </el-table>
      <pagination
        v-show="dialogTotal > 0"
        :total="dialogTotal"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getListDataNoIn"
      />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listUpdate,
  getUpdate,
  delUpdate,
  addUpdate,
  updateUpdate,
  getListDataNoIn,
} from "@/api/equipment/dataUpdate";
export default {
  components: {},
  props:{
    equipmentId:{
      type:Number,
    }
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      statusOptions: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      dialogTotal: 0,
      // 设备数据更新表格数据
      updateList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        corpClientId: null,
        dataTypeId: null,
        dataName: null,
        downloadUrl: null,
        status: null,
        equipmentId: null,
      },
      // 表单参数
      noInData: [],
      selectOfflineDataId: "",
    };
  },
  created() {
    this.queryParams.equipmentId = this.equipmentId;
    this.getList();
    this.getDicts("sys_normal_disable").then((response) => {
      this.statusOptions = response.data;
    });
  },
  methods: {
    /** 查询设备数据更新列表 */
    getList() {
      this.loading = true;
      listUpdate(this.queryParams).then((response) => {
        this.updateList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 查询未关联的数据
    getListDataNoIn() {
      getListDataNoIn(this.queryParams).then((response) => {
        this.noInData = response.rows;
        this.dialogTotal = response.total;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.selectOfflineDataId = "";
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.dataUpdateId);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.getListDataNoIn();
      this.open = true;
      this.title = "添加设备关联数据";
    },
    // 新增数据关联
    noInDataSelect(data) {
      this.selectOfflineDataId = data.offlineDataId;
    },
    // 数据关联状态修改
    handleStatusChange(row) {
      let text = row.status === "1" ? "启用" : "停用";
      this.$confirm(
        '确认要"' + text + '""' + row.dataName + '"的关联吗?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(function () {
          return updateUpdate({
            dataUpdateId: row.dataUpdateId,
            status: row.status,
          });
        })
        .then(() => {
          this.$modal.msgSuccess(text + "成功");
        })
        .catch(function () {
          row.status = row.status === "1" ? "1" : "0";
        });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const dataUpdateId = row.dataUpdateId || this.ids;
      getUpdate(dataUpdateId).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = "修改设备数据更新";
      });
    },
    /** 提交按钮 */
    submitForm() {
      let addData = {
        equipmentId: this.queryParams.equipmentId,
        offlineDataId: this.selectOfflineDataId,
      };
      if (addData.offlineDataId !== "") {
        addUpdate(addData).then((res) => {
          if (res.code == 1) {
            this.$modal.msgSuccess("新增关联成功");
            this.getList();
            this.open = false;
          }
        });
      }else{
        this.open = false;
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const dataUpdateIds = row.dataUpdateId || this.ids;
      this.$confirm(
        '是否确认删除设备数据更新编号为"' + dataUpdateIds + '"的数据项?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(function () {
          return delUpdate(dataUpdateIds);
        })
        .then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        });
    },
    /** 导出按钮操作 */
    // handleExport() {
    //   const queryParams = this.queryParams;
    //   this.$confirm("是否确认导出所有设备数据更新数据项?", "警告", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(function () {
    //       return exportUpdate(queryParams);
    //     })
    //     .then((response) => {
    //       this.download(response.msg);
    //     });
    // },
  },
};
</script>

