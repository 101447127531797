import request from '@/utils/request'

// 查询poi列表
export function listPoi(query) {
  return request({
    url: '/poi/poi/list',
    method: 'get',
    params: query
  })
}

// 查询poi详细
export function getPoi(poiId) {
  return request({
    url: '/poi/poi/' + poiId,
    method: 'get'
  })
}

// 新增poi
export function addPoi(data) {
  return request({
    url: '/poi/poi',
    method: 'post',
    data: data
  })
}

// 修改poi
export function updatePoi(data) {
  return request({
    url: '/poi/poi',
    method: 'put',
    data: data
  })
}

// 删除poi
export function delPoi(poiId) {
  return request({
    url: '/poi/poi/' + poiId,
    method: 'delete'
  })
}

// 导出poi
export function exportPoi(query) {
  return request({
    url: '/poi/poi/export',
    method: 'get',
    params: query
  })
}
