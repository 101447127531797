import request from '@/utils/request'

// 查询设备POI中间列表
export function listEquipmentPoi(query) {
  return request({
    url: '/equipmentPoi/equipmentPoi/list',
    method: 'get',
    params: query
  })
}

// 查询设备POI中间详细
export function getEquipmentPoi(equipmentPoiId) {
  return request({
    url: '/equipmentPoi/equipmentPoi/' + equipmentPoiId,
    method: 'get'
  })
}

// 新增设备POI中间
export function addEquipmentPoi(data) {
  return request({
    url: '/equipmentPoi/equipmentPoi',
    method: 'post',
    data: data
  })
}

// 修改设备POI中间
export function updateEquipmentPoi(data) {
  return request({
    url: '/equipmentPoi/equipmentPoi',
    method: 'put',
    data: data
  })
}

// 删除设备POI中间
export function delEquipmentPoi(equipmentPoiId) {
  return request({
    url: '/equipmentPoi/equipmentPoi/' + equipmentPoiId,
    method: 'delete'
  })
}

// 导出设备POI中间
export function exportEquipmentPoi(query) {
  return request({
    url: '/equipmentPoi/equipmentPoi/export',
    method: 'get',
    params: query
  })
}
