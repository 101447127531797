<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="属性名称" prop="configName">
        <el-input
          v-model="queryParams.configName"
          placeholder="请输入属性名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['equipmentConfig:equipmentConfig:add']"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['equipmentConfig:equipmentConfig:edit']"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['equipmentConfig:equipmentConfig:remove']"
        >删除</el-button>
      </el-col>

	  <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="equipmentConfigList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="设备配置属性ID" align="center" prop="equipmentConfigId" />
      <el-table-column label="设备" align="center" prop="equipmentName" />
      <el-table-column label="配置名称" align="center" prop="configName" />
      <el-table-column label="配置内容" align="center" prop="configValue" />
      <el-table-column label="状态" align="center" prop="status" :formatter="statusFormat"/>
      <el-table-column label="展示方式" align="center" prop="displayMode" :formatter="displayModeFormat"/>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['equipmentConfig:equipmentConfig:edit']"
          >修改</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['equipmentConfig:equipmentConfig:remove']"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改设备属性配置对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="设备" prop="equipmentId">
          <el-select v-model="form.equipmentId" placeholder="请选择设备" :disabled="true">
            <el-option
              v-for="equipment in equipmentList"
              :key="equipment.equipmentId"
              :label="equipment.equipmentName"
              :value="equipment.equipmentId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配置名称" prop="configName">
          <el-input v-model="form.configName" placeholder="请输入内容" />
        </el-form-item>
        <el-form-item label="配置内容" prop="configValue">
          <el-input v-model="form.configValue" placeholder="请输入配置内容" />
          <el-button type="primary" size="small" @click="selectContent">选择内容</el-button>
        </el-form-item>
        <el-form-item label="设备展示方式" prop="displayMode" v-if="configType != 0">
          <el-select v-model="form.displayMode" placeholder="请选择展示方式" :disabled="true">
            <el-option
              v-for="dict in displayModeOptions"
              :key="dict.dictValue"
              :label="dict.dictLabel"
              :value="dict.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入内容" />
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
            <el-radio
              v-for="dict in statusOptions"
              :key="dict.dictValue"
              :label="dict.dictValue"
            >{{dict.dictLabel}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="属性类型">
          <el-radio-group v-model="configType">
            <el-radio :label="0">共有属性</el-radio>
            <el-radio :label="1">专有属性</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>


    <!-- 选择内容弹框 -->
    <el-dialog :close-on-click-modal="false" title="内容选择" :visible.sync="selectOpen" width="50%" append-to-body>

      <el-form :model="queryParams" ref="multipleTable" :inline="true" v-show="showSearch" label-width="68px">
        <el-form-item label="别名" prop="alias">
          <el-input
            v-model="queryParams.alias"
            placeholder="请输入数据别名"
            clearable
            size="small"
            @keyup.enter.native="dataSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="cyan" icon="el-icon-search" size="mini" @click="dataSearch">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>

      <el-table ref="multipleTable" :data="noInData" tooltip-effect="dark" class="tourist-list" @row-click="noInDataSelect">
        <el-table-column width="65" align="center" >
          <template slot-scope="scope">
            <el-radio style="padding-left: 35px" :label="scope.row.offlineDataId" v-model="selectOfflineDataId"/>
          </template>
        </el-table-column>
        <el-table-column label="离线数据ID" prop="offlineDataId" align="center"/>
        <el-table-column prop="dataName" label="数据名称" align="center"/>
        <el-table-column prop="alias" label="别名" align="center"/>
      </el-table>
      <pagination
        v-show="dialogTotal > 0"
        :total="dialogTotal"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getListDataNoIn"
      />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitSelect">确 定</el-button>
        <el-button @click="cancelSelect">取 消</el-button>
      </div>
    </el-dialog>



  </div>
</template>

<script>
import { listEquipmentConfig, getEquipmentConfig, delEquipmentConfig, addEquipmentConfig, updateEquipmentConfig, exportEquipmentConfig } from "@/api/equipment/equipmentConfig";
import { listEquipment, getEquipment } from "@/api/equipment/equipment";
import { listData } from "@/api/equipment/offData";

export default {
  name: "EquipmentConfig",
  components: {
  },
  props:{
    equipmentId:{
      type:Number,
    }
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      dialogTotal: 0,
      // 设备属性配置表格数据
      equipmentConfigList: [],
      equipmentList: [],
      statusOptions: [],
      displayModeOptions: [],
      equipment:{},
      // 弹出层标题
      title: "",
      configType: null,
      // 是否显示弹出层
      open: false,
      selectOpen: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        configName: null,
        corpClientId: null,
        displayMode: null
      },
      // 表单参数
      form: {},
      noInData: [],
      selectOfflineDataId: "",
      selectContentName:"",
      selectContentAlias:"",
      // 表单校验
      rules: {
        configName: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ],
        configValue: [
          { required: true, message: "内容不能为空", trigger: "blur" }
        ],
      }
    };
  },
  created() {
    this.getList();
    this.getDicts("display_mode").then((response) => {
      this.displayModeOptions = response.data;
    });
    this.getDicts("sys_normal_disable").then((response) => {
      this.statusOptions = response.data;
    });
    this.getEquipment();
    this.getEquipmentById();
  },
  methods: {
    /** 查询设备属性配置列表 */
    getList() {
      this.loading = true;
      this.queryParams.equipmentId = this.equipmentId
      listEquipmentConfig(this.queryParams).then(response => {
        this.equipmentConfigList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 查询设备列表 */
    getEquipment(){
      this.loading = true;
      let equipmentParams = {}
      listEquipment(equipmentParams).then(response => {
        this.equipmentList = response.rows;
        this.loading = false;
      });
    },
    /** 查询设备详细 */
    getEquipmentById(){
      getEquipment(this.equipmentId).then(response => {
        this.equipment = response.data;
      });
    },
    // 查询未选择的内容
    getListDataNoIn() {
      listData(this.queryParams).then((response) => {
        this.noInData = response.rows;
        this.dialogTotal = response.total;
      });
    },
    // 字典状态字典翻译
    displayModeFormat(row, column) {
      return this.selectDictLabel(this.displayModeOptions, row.displayMode);
    },
    // 字典状态字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
      this.selectOfflineDataId = [];
    },
    // 表单重置
    reset() {
      this.form = {
        equipmentConfigId: null,
        equipmentId: null,
        configName: null,
        configValue: null,
        corpClientId: null,
        status: "1",
        delFlag: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null,
        remark: null,
        displayMode: null
      };
      this.configType = null;
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.equipmentConfigId)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.selectOfflineDataId = [];
      this.open = true;
      this.title = "添加设备属性配置";
      this.form.equipmentId = this.equipmentId;
      this.form.displayMode = this.equipment.displayMode;
      this.configType = 1;
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.selectOfflineDataId = [];
      const equipmentConfigId = row.equipmentConfigId || this.ids
      getEquipmentConfig(equipmentConfigId).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改设备属性配置";
        if(response.data.displayMode != null){
          this.configType = 1;
        }else{
          this.configType = 0;
        }
        this.form.displayMode = this.equipment.displayMode;
      });
    },
    /** 提交按钮 */
    submitForm() {
      if(this.configType == 0){
        this.form.displayMode = null;
      }
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.equipmentConfigId != null) {
            updateEquipmentConfig(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addEquipmentConfig(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const equipmentConfigIds = row.equipmentConfigId || this.ids;
      this.$confirm('是否确认删除设备属性配置编号为"' + equipmentConfigIds + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delEquipmentConfig(equipmentConfigIds);
        }).then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        })
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有设备属性配置数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportEquipmentConfig(queryParams);
        }).then(response => {
          this.download(response.msg);
        })
    },
    //内容选择弹框
    selectContent(){
      this.selectOpen = true;
      this.getListDataNoIn();
    },
    // 内容关联
    noInDataSelect(data) {
      this.selectOfflineDataId = data.offlineDataId;
      this.selectContentAlias = data.alias;
      this.selectContentName = data.dataName;
    },
    //将选择的内容的别名或名称填入configValue中
    submitSelect(){
      if(this.selectContentAlias!=null){
        this.form.configValue = this.selectContentName+"("+this.selectContentAlias+")"
      }else{
        this.form.configValue = this.selectContentName;
      }
      this.selectOpen = false;
    },
    //取消内容选择弹框
    cancelSelect(){
      this.selectOpen = false;
    },
    //内容弹框搜索按钮操作
    dataSearch() {
      this.queryParams.pageNum = 1;
      this.getListDataNoIn();
    },
    //内容弹框重置按钮操作
    resetSearch() {
      this.queryParams.alias = "";
      this.dataSearch();
    },
  }
};
</script>
