<template>
  <div class="app-container">
    <el-row>
      <el-col :span="12" class="card-box">
        <el-card>
          <div slot="header"><span>数据概览</span></div>
          <div class="el-table el-table--enable-row-hover el-table--medium">
            <table cellspacing="0" style="width: 100%;">
              <tbody>
              <tr>
                <td><div class="cell">{{welcomeTitle}}</div></td>
              </tr>
              </tbody>
            </table>
          </div>
        </el-card>
      </el-col>

    </el-row>
  </div>
</template>

<script>
  import { orderDataOverview,otherDataOverview,orderStatusTrend } from "@/api/index";
  import echarts from 'echarts'
  export default {
    name: "Server",
    data() {
      return {
        loading: [],
        server: [],
        orderData:{},
        otherData:{},
        seriesList:[],
        dateList:[],
        welcomeTitle:''
      };
    },
    created() {
     // this.orderDataOverview();
     // this.otherDataOverview();
    //  this.orderStatusTrend();
      this.getConfigKey("sys.pc.base.config").then(response => {
        this.welcomeTitle = '欢迎来到'+ JSON.parse(response.data).webSiteName
      });
    },
    // DOM 渲染完成触发
    mounted() {

    },
    methods: {
      /** 订单数据概览 */
      orderDataOverview() {
        this.openLoading();
        orderDataOverview().then(response => {
          this.orderData=response.data;
          //console.log(response.data);
          this.loading.close();
        });
      },
      /** 其它数据概览 */
      otherDataOverview() {
        this.openLoading();
        otherDataOverview().then(response => {
          this.otherData=response.data;
          //console.log(response.data);
          this.loading.close();
        });
      },
      /** 订单状态趋势 */
      orderStatusTrend() {
        this.openLoading();
        orderStatusTrend().then(response => {
         // this.otherData=response.data;
          this.seriesList=response.data.seriesList;

          this.dateList=response.data.dateList;
          // 1. 基于准备好的dom，初始化echarts实例
          var myChart = echarts.init(document.getElementById('echarts_box'))

          let  option = {
            title: {
              text: ''
            },
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['待付款', '待发货', '待收货', '待评价', '已完成']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: this.dateList
            },
            yAxis: {
              type: 'value'
            },
            series: this.seriesList
          };
          // 3. 使用刚指定的配置项和数据，显示图表
          myChart.setOption(option)

          console.log(JSON.stringify(this.dateList))
          //console.log(response.data);
          this.loading.close();
        });
      },

      // 打开加载层
      openLoading() {
        this.loading = this.$loading({
          lock: true,
          text: "拼命读取中",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      }
    }
  };
</script>
