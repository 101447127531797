import request from '@/utils/request'

// 查询设备日志列表
export function listEquipmentLog(query) {
  return request({
    url: '/equipmentLog/equipmentLog/list',
    method: 'get',
    params: query
  })
}
// 查询设备日志列表
export function getAttitudeLogList(query) {
  return request({
    url: '/equipmentLog/equipmentLog/selectSysEquipmentAttitudeLogList',
    method: 'get',
    params: query
  })
}

// 查询设备日志详细
export function getEquipmentLog(equipmentLogId) {
  return request({
    url: '/equipmentLog/equipmentLog/' + equipmentLogId,
    method: 'get'
  })
}

// 新增设备日志
export function addEquipmentLog(data) {
  return request({
    url: '/equipmentLog/equipmentLog',
    method: 'post',
    data: data
  })
}

// 修改设备日志
export function updateEquipmentLog(data) {
  return request({
    url: '/equipmentLog/equipmentLog',
    method: 'put',
    data: data
  })
}

// 删除设备日志
export function delEquipmentLog(equipmentLogId) {
  return request({
    url: '/equipmentLog/equipmentLog/' + equipmentLogId,
    method: 'delete'
  })
}

// 导出设备日志
export function exportEquipmentLog(query) {
  return request({
    url: '/equipmentLog/equipmentLog/export',
    method: 'get',
    params: query
  })
}
