import { render, staticRenderFns } from "./index.vue?vue&type=template&id=a83bd3b0&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\git\\ssgt_pc_admin_front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a83bd3b0')) {
      api.createRecord('a83bd3b0', component.options)
    } else {
      api.reload('a83bd3b0', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=a83bd3b0&", function () {
      api.rerender('a83bd3b0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/index.vue"
export default component.exports