import request from '@/utils/request'

// 查询离线数据列表
export function listData(query) {
  return request({
    url: 'equipment/offData/list',
    method: 'get',
    params: query
  })
}

// 查询离线数据详细
export function getData(offlineDataId) {
  return request({
    url: 'equipment/offData/' + offlineDataId,
    method: 'get'
  })
}

// 新增离线数据
export function addData(data) {
  return request({
    url: 'equipment/offData',
    method: 'post',
    data: data
  })
}

// 修改离线数据
export function updateData(data) {
  return request({
    url: 'equipment/offData',
    method: 'put',
    data: data
  })
}

// 删除离线数据
export function delData(offlineDataId) {
  return request({
    url: 'equipment/offData/' + offlineDataId,
    method: 'delete'
  })
}

// 导出离线数据
export function exportData(query) {
  return request({
    url: 'equipment/offData/export',
    method: 'get',
    params: query
  })
}
