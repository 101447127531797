<template>
  <div></div>
</template>

<script>
import { agentLogin } from '@/api/login'
export default {
  name: "agentLogin",
  data() {
    return {


    };
  },
  watch: {

  },
  created() {
    this.autoLogin();
  },
  methods: {

    autoLogin(){
      let agentId = this.$route.query.agentId;
      let agentSecret = this.$route.query.agentSecret;

      let loginForm = {
        agentId: agentId,
        agentSecret: agentSecret,

      };


      console.info(agentId)
      console.info(agentSecret)

      this.$store.dispatch("AgentLogin", loginForm).then(() => {
        this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
      }).catch((res) => {
        // this.showErrorMsg(res.msg)
      });



      // agentLogin(agentId,agentSecret).then(res => {

      //   if(res.code == '1'){

      //     this.$router.push({ path: this.redirect || "/" }).catch(()=>{});

      //   }else{
      //     this.showErrorMsg(res.message)
      //   }

      // })


    },

    showErrorMsg(msg){
      this.$message.error(msg);
    }
  }
};
</script>
