var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "card-box", attrs: { span: 12 } },
            [
              _c("el-card", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("span", [_vm._v("数据概览")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "el-table el-table--enable-row-hover el-table--medium",
                  },
                  [
                    _c(
                      "table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { cellspacing: "0" },
                      },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v(_vm._s(_vm.welcomeTitle)),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }