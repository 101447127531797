import { render, staticRenderFns } from "./basicInfoForm.vue?vue&type=template&id=f6a95578&"
import script from "./basicInfoForm.vue?vue&type=script&lang=js&"
export * from "./basicInfoForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\git\\ssgt_pc_admin_front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f6a95578')) {
      api.createRecord('f6a95578', component.options)
    } else {
      api.reload('f6a95578', component.options)
    }
    module.hot.accept("./basicInfoForm.vue?vue&type=template&id=f6a95578&", function () {
      api.rerender('f6a95578', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/tool/gen/basicInfoForm.vue"
export default component.exports