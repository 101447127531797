import request from '@/utils/request'

// 查询设备属性配置列表
export function listEquipmentConfig(query) {
  return request({
    url: '/equipmentConfig/equipmentConfig/list',
    method: 'get',
    params: query
  })
}

// 查询设备属性配置详细
export function getEquipmentConfig(equipmentConfigId) {
  return request({
    url: '/equipmentConfig/equipmentConfig/' + equipmentConfigId,
    method: 'get'
  })
}

// 新增设备属性配置
export function addEquipmentConfig(data) {
  return request({
    url: '/equipmentConfig/equipmentConfig',
    method: 'post',
    data: data
  })
}

// 修改设备属性配置
export function updateEquipmentConfig(data) {
  return request({
    url: '/equipmentConfig/equipmentConfig',
    method: 'put',
    data: data
  })
}

// 删除设备属性配置
export function delEquipmentConfig(equipmentConfigId) {
  return request({
    url: '/equipmentConfig/equipmentConfig/' + equipmentConfigId,
    method: 'delete'
  })
}

// 导出设备属性配置
export function exportEquipmentConfig(query) {
  return request({
    url: '/equipmentConfig/equipmentConfig/export',
    method: 'get',
    params: query
  })
}