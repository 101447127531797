import request from '@/utils/request'

// 查询转向杆列表
export function listSteeringLever(query) {
  return request({
    url: '/steeringLever/steeringLever/list',
    method: 'get',
    params: query
  })
}

// 查询转向杆详细
export function getSteeringLever(steeringLeverId) {
  return request({
    url: '/steeringLever/steeringLever/' + steeringLeverId,
    method: 'get'
  })
}

// 新增转向杆
export function addSteeringLever(data) {
  return request({
    url: '/steeringLever/steeringLever',
    method: 'post',
    data: data
  })
}

// 修改转向杆
export function updateSteeringLever(data) {
  return request({
    url: '/steeringLever/steeringLever',
    method: 'put',
    data: data
  })
}

// 删除转向杆
export function delSteeringLever(steeringLeverId) {
  return request({
    url: '/steeringLever/steeringLever/' + steeringLeverId,
    method: 'delete'
  })
}

// 导出转向杆
export function exportSteeringLever(query) {
  return request({
    url: '/steeringLever/steeringLever/export',
    method: 'get',
    params: query
  })
}