<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="100px">
      <el-form-item label="转向杆名称" prop="steeringLeverName">
        <el-input
          v-model="queryParams.steeringLeverName"
          placeholder="请输入转向杆名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="转向杆ip" prop="ip">
        <el-input
          v-model="queryParams.ip"
          placeholder="请输入转向杆ip"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="queryParams.status" placeholder="状态" clearable size="small">
          <el-option
            v-for="dict in statusOptions"
            :key="dict.dictValue"
            :label="dict.dictLabel"
            :value="dict.dictValue"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['steeringLever:steeringLever:add']"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['steeringLever:steeringLever:edit']"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['steeringLever:steeringLever:remove']"
        >删除</el-button>
      </el-col>

	  <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="steeringLeverList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="转向杆ID" align="center" prop="steeringLeverId" />
      <el-table-column label="设备" align="center" prop="equipmentName" />
      <el-table-column label="转向杆名称" align="center" prop="steeringLeverName" />
      <el-table-column label="转向杆ip" align="center" prop="ip" />
      <el-table-column label="状态" align="center" prop="status" :formatter="statusFormat" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['steeringLever:steeringLever:edit']"
          >修改</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['steeringLever:steeringLever:remove']"
          >删除</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-position"
            @click="handleAngle(scope.row)"
          >角度</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改转向杆对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="设备" prop="equipmentId">
          <el-select v-model="form.equipmentId" placeholder="请选择设备" :disabled="true">
            <el-option
              v-for="equipment in equipmentList"
              :key="equipment.equipmentId"
              :label="equipment.equipmentName"
              :value="equipment.equipmentId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="转向杆名称" prop="steeringLeverName">
          <el-input v-model="form.steeringLeverName" placeholder="请输入转向杆名称" />
        </el-form-item>
        <el-form-item label="转向杆ip" prop="ip">
          <el-input v-model="form.ip" placeholder="请输入转向杆ip" />
        </el-form-item>
        <el-form-item label="转向杆内容">
          <editor v-model="form.content" :min-height="192"/>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
            <el-radio-group v-model="form.status">
              <el-radio
                v-for="dict in statusOptions"
                :key="dict.dictValue"
                :label="dict.dictValue"
              >{{dict.dictLabel}}</el-radio>
            </el-radio-group>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>


    <el-dialog title="旋转角度" @close="angleDialog=false" :close-on-click-modal="false" :visible.sync="angleDialog" width="1200px" append-to-body>
      <equipment-angle :equipmentId="equipmentId" :steeringLeverId="steeringLeverId" v-if="angleDialog"></equipment-angle>
    </el-dialog>
  </div>
</template>

<script>
import { listSteeringLever, getSteeringLever, delSteeringLever, addSteeringLever, updateSteeringLever, exportSteeringLever } from "@/api/equipment/steeringLever";
import Editor from '@/components/Editor';
import { listEquipment } from "@/api/equipment/equipment";
import EquipmentAngle from "../equipment/angle"

export default {
  name: "SteeringLever",
  props:{
    equipmentId:{
      type:Number,
    }
  },
  components: {
    EquipmentAngle,
    Editor,
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 转向杆表格数据
      steeringLeverList: [],
      statusOptions: [],
      equipmentList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        equipmentId: null,
        steeringLeverName: null,
        ip: null,
        corpClientId: null,
        status: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        equipmentId:[
          { required: true, message: "设备不能为空", trigger: "blur" }
        ],
        steeringLeverName:[
          { required: true, message: "转向杆名称不能为空", trigger: "blur" }
        ],
        ip:[
          { required: true, message: "转向杆ip不能为空", trigger: "blur" }
        ],
      },

      steeringLeverId: null,
      angleDialog: false
    };
  },
  created() {
    this.getList();
    this.getDicts("sys_normal_disable").then((response) => {
      this.statusOptions = response.data;
    });
    this.getEquipment();
  },
  methods: {
    /** 查询转向杆列表 */
    getList() {
      this.loading = true;
      this.queryParams.equipmentId = this.equipmentId
      listSteeringLever(this.queryParams).then(response => {
        this.steeringLeverList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 查询设备列表 */
    getEquipment(){
      this.loading = true;
      let equipmentParams = {}
      listEquipment(equipmentParams).then(response => {
        this.equipmentList = response.rows;
        this.loading = false;
      });
    },
    // 字典状态字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        steeringLeverId: null,
        equipmentId: null,
        steeringLeverName: null,
        ip: null,
        content: null,
        corpClientId: null,
        status: "0",
        delFlag: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null,
        remark: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 设备角度按钮操作 */
    handleAngle(row){
      this.steeringLeverId = row.steeringLeverId;
      this.angleDialog = true;
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.steeringLeverId)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加转向杆";
      this.form.equipmentId = this.equipmentId;
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const steeringLeverId = row.steeringLeverId || this.ids
      getSteeringLever(steeringLeverId).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改转向杆";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.steeringLeverId != null) {
            updateSteeringLever(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addSteeringLever(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const steeringLeverIds = row.steeringLeverId || this.ids;
      this.$confirm('是否确认删除转向杆编号为"' + steeringLeverIds + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delSteeringLever(steeringLeverIds);
        }).then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        })
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有转向杆数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportSteeringLever(queryParams);
        }).then(response => {
          this.download(response.msg);
        })
    }
  }
};
</script>
