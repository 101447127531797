import request from '@/utils/request'

// 查询设备增值服务列表
export function listEquipmentVas(query) {
  return request({
    url: '/equipmentVas/equipmentVas/list',
    method: 'get',
    params: query
  })
}

// 查询设备增值服务详细
export function getEquipmentVas(equipmentVasId) {
  return request({
    url: '/equipmentVas/equipmentVas/' + equipmentVasId,
    method: 'get'
  })
}

// 新增设备增值服务
export function addEquipmentVas(data) {
  return request({
    url: '/equipmentVas/equipmentVas',
    method: 'post',
    data: data
  })
}

// 修改设备增值服务
export function updateEquipmentVas(data) {
  return request({
    url: '/equipmentVas/equipmentVas',
    method: 'put',
    data: data
  })
}

// 删除设备增值服务
export function delEquipmentVas(equipmentVasId) {
  return request({
    url: '/equipmentVas/equipmentVas/' + equipmentVasId,
    method: 'delete'
  })
}

// 导出设备增值服务
export function exportEquipmentVas(query) {
  return request({
    url: '/equipmentVas/equipmentVas/export',
    method: 'get',
    params: query
  })
}