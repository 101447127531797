<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="80px">
      <el-form-item label="poi点" prop="poiId">
        <el-select v-model="queryParams.poiId" placeholder="请选择poi点" clearable size="small">
          <el-option
            v-for="poi in poiList"
            :key="poi.poiId"
            :label="poi.poiName"
            :value="poi.poiId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="cyan" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          >删除</el-button>
      </el-col>

      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="equipmentPoiList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="编号" align="center" prop="equipmentPoiId" />
      <el-table-column label="设备" align="center" prop="equipmentName" />
      <el-table-column label="poi点" align="center" prop="poiName" />
      <el-table-column label="旋转角度" align="center" prop="rotationAngle" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改设备POI中间对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="设备" prop="equipmentId" >
          <el-select v-model="form.equipmentId" placeholder="请选择设备ID" :disabled="true">
            <el-option
              v-for="equipment in equipmentList"
              :key="equipment.equipmentId"
              :label="equipment.equipmentName"
              :value="equipment.equipmentId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="转向杆" prop="steeringLeverId" >
          <el-select v-model="form.steeringLeverId" placeholder="请选择转向杆" :disabled="true">
            <el-option
              v-for="steeringLever in steeringLeverList"
              :key="steeringLever.steeringLeverId"
              :label="steeringLever.steeringLeverName"
              :value="steeringLever.steeringLeverId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="poi点" prop="poiId">
          <el-select v-model="form.poiId" placeholder="请选择poi点">
            <el-option
              v-for="poi in poiList"
              :key="poi.poiId"
              :label="poi.poiName"
              :value="poi.poiId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="旋转角度" prop="rotationAngle">
          <el-input v-model="form.rotationAngle" placeholder="请输入旋转角度" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { listEquipmentPoi, getEquipmentPoi, delEquipmentPoi, addEquipmentPoi, updateEquipmentPoi, exportEquipmentPoi } from "@/api/equipment/equipmentPoi";
  import { listEquipment } from "@/api/equipment/equipment";
  import { listPoi } from "@/api/poi/poi";
  import { listSteeringLever } from "@/api/equipment/steeringLever";

export default {
  name: "EquipmentPoi",
  components: {
  },
  props:{
    equipmentId:{
      type:Number,
    },
    steeringLeverId:{
      type:Number,
    },
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 设备POI中间表格数据
      equipmentPoiList: [],
      equipmentList: [],
      poiList: [],
      steeringLeverList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        equipmentId: null,
        poiId: null,
        corpClientId: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        poiId:[
          { required: true, message: "POI不能为空", trigger: "blur" }
        ],
        rotationAngle:[
          { required: true, message: "角度不能为空", trigger: "blur" }
        ],
      }
    };
  },
  created() {
    this.getList();
    this.getEquipment();
    this.getPoi();
    this.getSteeringLeverList();
  },
  // watch:{
  //   '$route':{
  //     handler(route){
  //       const that=this
  //       debugger
  //       if(route.name==='equipmentAngle'){
  //         this.queryParams.equipmentId=route.query.equipmentId
  //         that.getList()
  //       }
  //     },
  //     deep:true
  //   }
  // },
  methods: {
    /** 查询设备POI中间列表 */
    getList() {
      this.loading = true;
      this.queryParams.steeringLeverId = this.steeringLeverId
      listEquipmentPoi(this.queryParams).then(response => {
        this.equipmentPoiList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 查询转向杆列表 */
    getSteeringLeverList() {
      this.loading = true;
      let steeringLeverParams = {}
      listSteeringLever(steeringLeverParams).then(response => {
        this.steeringLeverList = response.rows;
        this.loading = false;
      });
    },
    /** 查询设备列表 */
    getEquipment(){
      this.loading = true;
      let equipmentParams = {}
      listEquipment(equipmentParams).then(response => {
        this.equipmentList = response.rows;
        this.loading = false;
      });
    },
    /** 查询POI列表 */
    getPoi(){
      this.loading = true;
      let poiParams = {
        pageNum: 1,
        pageSize: 9999,}
      listPoi(poiParams).then(response => {
        this.poiList = response.rows;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        equipmentPoiId: null,
        equipmentId: null,
        poiId: null,
        rotationAngle: null,
        corpClientId: null,
        status: "0",
        delFlag: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null,
        remark: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.equipmentPoiId)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加设备POI点位旋转角度";
      this.form.equipmentId = this.equipmentId;
      this.form.steeringLeverId = this.steeringLeverId;
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const equipmentPoiId = row.equipmentPoiId || this.ids
      getEquipmentPoi(equipmentPoiId).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改设备POI点位旋转角度";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.equipmentPoiId != null) {
            updateEquipmentPoi(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addEquipmentPoi(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const equipmentPoiIds = row.equipmentPoiId || this.ids;
      this.$confirm('是否确认删除编号为"' + equipmentPoiIds + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return delEquipmentPoi(equipmentPoiIds);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有设备POI中间数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return exportEquipmentPoi(queryParams);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>

