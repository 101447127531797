import request from '@/utils/request'


//订单数据概览
export function orderDataOverview() {
  return request({
    url: '/data/overview/order',
    method: 'get'
  })
}
//其它数据概览
export function otherDataOverview() {
  return request({
    url: '/data/overview/other',
    method: 'get'
  })
}

//订单状态趋势
export function orderStatusTrend() {
  return request({
    url: '/data/overview/order/statusTrend',
    method: 'get'
  })
}

