import request from '@/utils/request'

// 查询设备列表
export function listEquipment(query) {
  return request({
    url: '/equipment/equipment/list',
    method: 'get',
    params: query
  })
}

// 查询设备详细
export function getEquipment(equipmentId) {
  return request({
    url: '/equipment/equipment/' + equipmentId,
    method: 'get'
  })
}

// 新增设备
export function addEquipment(data) {
  return request({
    url: '/equipment/equipment',
    method: 'post',
    data: data
  })
}

// 修改设备
export function updateEquipment(data) {
  return request({
    url: '/equipment/equipment',
    method: 'put',
    data: data
  })
}

// 删除设备
export function delEquipment(equipmentId) {
  return request({
    url: '/equipment/equipment/' + equipmentId,
    method: 'delete'
  })
}

// 导出设备
export function exportEquipment(query) {
  return request({
    url: '/equipment/equipment/export',
    method: 'get',
    params: query
  })
}

// 生成二维码
export function createQRCode(query) {
  return request({
    url: '/equipment/equipment/createQRCode',
    method: 'get',
    params: query
  })
}

// 复制新增
export function copyAddEquipment(equipmentId) {
  let data = {
    equipmentId:equipmentId
  }
  return request({
    url: '/equipment/equipment/copyAdd',
    method: 'post',
    data: data
  })
}

// 获取心跳记录
export function getEquipmentHearbeat(startDate,endDate,equipmentId) {
  return request({
    url: '/equipment/equipment/getEquipmentHearbeat',
    method: 'get',
    params: {startDate:startDate,endDate:endDate,equipmentId:equipmentId}
  })
}

// 新增设备
export function sendRemoteCommand(data) {
  return request({
    url: '/equipment/equipment/sendRemoteCommand',
    method: 'post',
    data: data
  })
}

// 设备配置检测
export function checkEquipmentConfig(equipmentId) {
  return request({
    url: '/equipment/equipment/selectEquipmentAllConfig/' + equipmentId,
    method: 'get'
  })
}

// 设备异常
export function exceptionalEquipment(data) {
  return request({
    url: '/equipment/equipment/exceptionalEquipment',
    method: 'put',
    data: data
  })
}

// 设备异常原因日志
export function selectExceptionalLog(equipmentId) {
  return request({
    url: '/equipment/equipment/selectEquipmentExceptionalLog/' + equipmentId,
    method: 'get'
  })
}
